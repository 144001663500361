export const Version = '1.0.1';
export const salesHost: string = 'https://store.guojiangmedia.com/rpc';
export const salesHostTest: string = 'http://sales-test.guojiangmedia.com/rpc';
export const hostUrl: string = 'https://gw.realtech-inc.com/rpc';
export const loginUrl: string = 'https://wxmpx.guojiangmedia.com/realtech';
export const UserCookieKey = 'WECHAT-H5-USERINFO'; 

export const msgTyepVar: any = {
  0: '未知消息',
  1: '文本',
  2: '图片',
  3: '语音',
  4: '名片',
  5: '视频',
  6: '表情包',
  7: '位置',
  8: '链接',
  9: '红包',
  10: '转账',
  11: '共享位置',
  12: '语音通话',
  13: '视频通话',
  14: '卡券',
  15: '文件',
  16: '小程序',
  30: '腾讯消息',
};
