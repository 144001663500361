import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {hostUrl, salesHost, salesHostTest, Version} from '@/utils/config';
import md5 from 'js-md5';
import {getUserID, getPkToken} from './auth';


const service = axios.create({
    baseURL: hostUrl,
    timeout: 50000, // 请求超时时间
    url: '',  // 备选请求url
});


// request 拦截器
service.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        if (config.url) {
            config.baseURL = config.url;
        }
        if (config.url && (config.url === salesHost || config.url === salesHostTest)) {
            const timestamp: number = parseInt(String(new Date().getTime()), 10);
            const userId: number = config.data.hasOwnProperty('user_id')
                ? config.data.user_id
                : parseInt(getUserID(), 10);
            const pkToken: string = getPkToken();
            const n: string = parseInt(String(Math.random() * 1000000), 10) + '';
            const a: string = md5(String(userId));
            const b: string = md5(String(timestamp));
            config.headers = Object.assign(config.headers, {
                userId,
                s: md5(a + pkToken + b + md5(n)),
                t: timestamp,
                r: n,
            });
            config.data.user_id = userId;
            if (!config.data.store_id) {
                config.data.store_id = 1; /// 暂时 @Test@
            }
            config.headers.Version = Version;
        }
        return config;
    }, (error) => {
        Promise.reject(error);
    },
);

// response 拦截器
service.interceptors.response.use(
    (response: AxiosResponse<any>) => {
        const res = response.data;
        if ((res.status && res.status.code === 0) || res.code === 0) {
            return response.data;
        } else {
          if (res.msg || res.status.msg) {
            console.error(res.msg || res.status.msg, 3000)
          }
            return Promise.reject(res);
        }
    },
    (error) => {
        if (error.message !== 'stopreq') {
          console.error(error.message || '网络错误', 3000)
        }
        return Promise.reject(error);
    },
);

export default service;
