import request from '@/utils/request'
import { hostUrl, salesHost } from '@/utils/config'

/**
 * @func 获取分组设备列表
 */
const getGroupDevice: any = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'FriendMsg.GetChatRobotList'
    },
    data
  })

/**
 * @func 会话列表
 */
const getFriendForMsgList: any = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'FriendMsg.GetFriendForMsgList'
    },
    data
  })

/**
 * @func 好友任务
 */
const friendTask: any = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'CircleFriends.FriendTask'
    },
    data
  })

/**
 * @func 获取好友列表
 */
const getFriendsList: any = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'ScrmRobot.GetFriendList'
    },
    data
  })

/**
 * @func 获取群聊列表
 */
const getChatRoom: any = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'FriendMsg.GetChatroomList'
    },
    data
  })

/**
 * @func 获取设备朋友圈
 */
const getDeviceMoments: any = (data: any) =>
  request({
    url: hostUrl,
    method: 'POST',
    headers: {
      servername: 'WechatCenter',
      methodname: 'WechatCenter.GetUserTimelineList'
    },
    data
  })

/**
 * @func 获取全部设备朋友圈
 */
const getAllMoments: any = (data: any) =>
  request({
    url: hostUrl,
    method: 'POST',
    headers: {
      servername: 'WechatCenter',
      methodname: 'WechatCenter.GetWechatTimelineList'
    },
    data
  })

/**
 * @func 微信任务
 */
const doWxTask: any = (data: any) =>
  request({
    url: hostUrl,
    method: 'POST',
    headers: {
      servername: 'WechatCenter',
      methodname: 'WechatCenter.DoTask'
    },
    data
  })

/**
 * @func 获取消息列表
 */
const getFriendMsgList: any = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'FriendMsg.GetFriendMsgList'
    },
    data
  })

/**
 * @func 获取新朋友申请列表
 */
const getNewFriendList: any = (data: any) =>
  request({
    url: hostUrl,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'ScrmRobot.GetWechatFriendVerifyList'
    },
    data
  })

/**
 * @func 刷新朋友圈
 */
const refreshTimeline: any = (data: any) =>
  request({
    url: hostUrl,
    method: 'POST',
    headers: {
      servername: 'WechatCenter',
      methodname: 'WechatCenter.RefreshTimeline'
    },
    data
  })

/**
 * @func 获取素材列表
 */
const getMaterialList = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcThings',
      methodname: 'EcMaterial.GetChatMaterialList'
    },
    data
  })


/**
 * @func 执行任务
 */
const doTask = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'CircleFriends.DoTask'
    },
    data
  })

/**
 * @func 群发消息
 */
const massTask = (data: any) =>
  request({
    url: hostUrl,
    method: 'POST',
    headers: {
      servername: 'WechatCenter',
      methodname: 'WechatCenter.MassTask'
    },
    data
  })

/**
 * @func 获取好友朋友圈
 */
const getUserTimelineList = (data: any) =>
  request({
    url: hostUrl,
    method: 'POST',
    headers: {
      servername: 'WechatCenter',
      methodname: 'WechatCenter.GetUserTimelineList'
    },
    data
  })

/**
 * @func 获取所有标签
 */
const getRobotTagList = (data: any): any =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'ScrmRobot.GetRobotTags'
    },
    data
  })

/**
 * @func 创建标签
 */
const createRobotTags = (data: any): any =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'ScrmRobot.CreateRobotTags'
    },
    data
  })

/**
 * @func 设置好友标签
 */
const setFriendTags = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'ScrmRobot.SetFriendTags'
    },
    data
  })

/**
 * @func 隐藏聊天记录
 */
const hideChatMsg = (data: any) =>
  request({
    url: hostUrl,
    method: 'POST',
    headers: {
      servername: 'WechatCenter',
      methodname: 'WechatCenter.HideChatMsg'
    },
    data
  })

/**
 * @func 消息发送任务（周）
 */
const doMessageTask = (data: any) =>
  request({
    url: hostUrl,
    method: 'POST',
    headers: {
      servername: 'WechatCenter',
      methodname: 'WechatCenter.DoMessageTask'
    },
    data
  })

export default {
  getGroupDevice,
  getFriendForMsgList,
  friendTask,
  getFriendsList,
  getChatRoom,
  getDeviceMoments,
  getAllMoments,
  doWxTask,
  getFriendMsgList,
  getNewFriendList,
  refreshTimeline,
  getMaterialList,
  doTask,
  massTask,
  getUserTimelineList,
  getRobotTagList,
  createRobotTags,
  setFriendTags,
  hideChatMsg,
  doMessageTask
}