import request from '@/utils/request'
import { loginUrl } from '@/utils/config'
import { getToken } from '@/utils/auth'

/**
 * @func 登录
 */
export const login = (userInfo: { username: string, password: string }) =>
  request({
    url: loginUrl + '/login',
    method: 'POST',
    data: {
      RealtechLoginSearch: userInfo,
      type: 19,
    }
  })

/**
 * @func 登出
 */
const logout = () =>
  request({
    url: loginUrl + '/logout',
    method: 'post',
    params: {
      token: getToken(),
    },
  });

/**
 * @func 获取上传token
 * @param data 
 * @returns 
 */
const getQiniuToken = (data: any) =>
  request({
    url: 'https://store.guojiangmedia.com/rpc',
    method: 'POST',
    headers: {
      ServerName: 'RealStoreGateway',
      MethodName: 'MiniGateway.GetQiNiuUploadToken',
    },
    data,
  });

export default{
  login,
  logout,
  getQiniuToken
}