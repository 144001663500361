import request from '@/utils/request'
import { hostUrl, salesHost } from '@/utils/config'

/**
 * @func 转换语音
 */
const converterVoice: any = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'FriendMsg.ConverterVoice'
    },
    data
  })

/**
 * @func 刷新单条语音数据
 */
const refreshVoice: any = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'FriendMsg.GetOneMsg'
    },
    data
  })

/**
 * @func 发送单条消息
 */
const sendSingleMessage = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'FriendMsg.DoMessageTask'
    },
    data
  })

/**
 * @func 获取公众号图片
 */
const sendMpQrcode = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'FriendMsg.SendMpQrcode'
    },
    data
  })

/**
 * @func 发送语音
 */
const sendVoice = (data: any) =>
  request({
    url: hostUrl,
    method: 'POST',
    headers: {
      servername: 'WechatCenter',
      methodname: 'WechatCenter.SendVoiceSingle'
    },
    data
  })

/**
 * @func 执行群操作
 */
const doChatroomTask = (data: any) =>
  request({
    url: hostUrl,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'FriendMsg.DoChatroomTask'
    },
    data
  })

/**
 * @func 获取微信好友列表（判断是否是自己的好友）
 */
const isMyFriend = (data: any) =>
  request({
    url: hostUrl,
    method: 'POST',
    headers: {
      servername: 'WechatCenter',
      methodname: 'WechatCenter.GetWechatContactList'
    },
    data
  })

/**
 * @func 获取好友信息
 */
const getFriendDetail = (data:any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'ScrmRobot.GetFriendDetail'
    },
    data
  })

/**
 * @func 好友任务
 */
const friendTask = (data: any) =>
  request({
    url: salesHost,
    method: 'POST',
    headers: {
      servername: 'EcScrm',
      methodname: 'CircleFriends.FriendTask'
    },
    data
  })

export default {
  converterVoice,
  refreshVoice,
  sendSingleMessage,
  sendMpQrcode,
  sendVoice,
  doChatroomTask,
  isMyFriend,
  getFriendDetail,
  friendTask
}